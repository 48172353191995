import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { css } from 'glamor'
import g from 'glamorous'

export default () => (
  <StaticQuery
    query={graphql`
      query HomeSlideShowMobile {
        slide1: file(relativePath: { eq: "square-1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Div>
        <Img
          fluid={data.slide1.childImageSharp.fluid}
          alt="Valerie Pasquio, interior design in New York City"
        />
        `
      </Div>
    )}
  />
)

const Div = g.div({})
