import React from 'react'
import Layout from '../components/Layout'
import HomeProjects from '../components/HomeProjects'
import HomeProjectsMobile from '../components/HomeProjectsMobile'
import g from 'glamorous'
import { Helmet } from 'react-helmet'

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Valerie Pasquiou Interiors & Design - Paris / New York</title>
      <meta
        name="description"
        content="Valerie Pasquiou Interiors & Design. Our services: interior architecture, interior design, space planning consulting, branding environment 
        design director."
      />
    </Helmet>
    <Desktop>
      <HomeProjects />
    </Desktop>
    <Mobile>
      <HomeProjectsMobile />
    </Mobile>
  </Layout>
)

export default IndexPage

const Desktop = g.div({
  display: `none`,
  '@media only screen and (min-width : 681px)': {
    display: `block`,
  },
})

const Mobile = g.div({
  display: `none`,
  '@media only screen and (max-width : 680px)': {
    display: `block`,
  },
})
