import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { css } from 'glamor'
import g from 'glamorous'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default () => (
  <StaticQuery
    query={graphql`
      query HomeSlideShow {
        slide0: file(relativePath: { eq: "slide00.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        slide1: file(relativePath: { eq: "gh-slider.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        slide2: file(relativePath: { eq: "slide2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        slide3: file(relativePath: { eq: "nm-slider.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Section>
        <Slider {...settings} adaptiveHeight={false}>
          <Div>
            <Img
              fluid={data.slide1.childImageSharp.fluid}
              alt="Valerie Pasquiou, interiors and design"
            />
          </Div>
          <Div>
            <Img
              fluid={data.slide0.childImageSharp.fluid}
              alt="Valerie Pasquiou, interiors and design"
            />
          </Div>
          <Div>
            <Img
              fluid={data.slide3.childImageSharp.fluid}
              alt="Interior design of a loft in TriBeCa by Valerie Pasquiou"
            />
          </Div>
        </Slider>
      </Section>
    )}
  />
)

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2200,
}

const Section = g.section({
  paddingTop: `50px`,
})

const Div = g.div({})
